.custom-link-class {
  border: none;
  /* Add other styles for your custom link icon here */
}

/* Add styles for the overall toolbar */
.rdw-editor-toolbar {
  border: none !important; /* This removes the border */
  /* Add other styles for the toolbar here */
}

/* If you want to style the icons generally */
.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  border: none !important; /* Removes border from icons and dropdowns */
  /* Add other general icon styles here */
}

.ql-toolbar.ql-snow {
  border: none !important;
}
